.leaderBoard {
  width: 100%;
  min-height: 100vh;
  padding: 150px 0;
  padding-top: 150px;
}

.titleAndSearchBox {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  padding-bottom: 15px;
}
.title {
  font-family: var(--primary-font);
  font-size: 36px;
  font-weight: 700;
  color: #1ced86;
}

.searchInput {
  font-family: var(--primary-font);
  font-size: 16px;
  font-weight: 400;
  outline: none;
  border: 1px solid #d6edff;
  background: #ddffef;
  padding: 8px 12px;
  width: 100%;
  max-width: 280px;
  border-radius: 5px;
}
.tableContainer {
  background: rgba(214, 237, 255, 0.1);
  border-radius: 22px;
  padding-bottom: 5px;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.heading,
.item {
  text-align: left;
  padding: 12px 15px;
  white-space: nowrap;
}
.heading,
.item {
  font-family: var(--primary-font);
  font-size: 16px;
  font-weight: 700;
  line-height: 125%;
  color: #d6edff;
}
.item {
  font-size: 14px;
  font-weight: 500;
}

tr:nth-child(even) {
  background-color: #1a1a1a;
}

/* Firefox */
.tableContainer {
  scrollbar-width: thin;
  scrollbar-color: #1ced86 rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari */
.tableContainer::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background: #1ced86;
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}

@media only screen and (max-width: 991px) {
  .leaderBoard {
    margin: 0 auto;
    grid-template-columns: 1fr;
    padding-top: 140px;
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .titleAndSearchBox {
    flex-direction: column;
    padding-bottom: 30px;
  }
}
