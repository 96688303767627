.historyContainer {
  background: #3b4349;
  border-radius: 12px;
  padding: 12px 6px;
  height: 100%;
}
.historyWrapper {
  display: grid;
  grid-template-columns: 90px 1fr;
}
.header,
.history {
  display: grid;
  grid-template-columns: 0.8fr 1fr auto;
  gap: 8px;
  align-items: center;
  padding: 0 8px;
}
.header {
  padding-bottom: 15px;
}
.title {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  color: rgba(214, 237, 255, 0.6);
}
.allHistories {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 500px;
  overflow: auto;
}
.historyWrapper {
  border-radius: 10px;
  padding: 8px 10px;
}
.histories {
  background: #1ced86;
}
.unstakeHistories {
  background: #798792;
}
.lockBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(24, 51, 46, 0.7);
  box-shadow: 0px 8px 12px rgba(25, 108, 72, 0.4);
  border-radius: 5px;
  max-width: 48px;
  height: 48px;
}
.unstakeLockBox {
  background: rgba(54, 64, 68, 0.7);
}

.lockIcon {
  width: 22px;
}

.text {
  font-family: "B612";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  text-transform: capitalize;
  color: #1e1e1e;
}

.unstakeHistories .text {
  color: #d6edff;
}
Firefox .grScrollbar {
  scrollbar-width: thin;
}

/* Chrome, Edge, and Safari */
.grScrollbar::-webkit-scrollbar {
  width: 2px;
}

.grScrollbar::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
}

.grScrollbar::-webkit-scrollbar-thumb {
  border-radius: 100vh;
  border: none;
}
@media only screen and (max-width: 520px) {
  .historyWrapper {
    display: grid;
    grid-template-columns: 40px 1fr;
  }
  .lockBox {
    max-width: 36px;
    height: 36px;
  }
  .lockIcon {
    width: 20px;
  }
}
@media only screen and (max-width: 380px) {
  .historyWrapper {
    display: grid;
    grid-template-columns: 30px 1fr;
  }
  .lockBox {
    max-width: 28px;
    height: 28px;
  }
  .lockIcon {
    width: 16px;
  }
  .historyWrapper {
    padding: 6px 6px;
  }
  .header,
  .history {
    padding: 0 5px;
  }
}
