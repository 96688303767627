.durationContainer {
  background: rgba(214, 237, 255, 0.12);
  border-radius: 16px;
  padding: 12px;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 10px;
}
.title {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #d6edff;
}
.text {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: rgba(214, 237, 255, 0.5);
}
.durations {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
}
.dayBox {
  display: grid;
  grid-template-columns: 1fr 48px;
  align-items: center;
  gap: 7px;
  padding: 5px;
  background: rgba(214, 237, 255, 0.2);
  border-radius: 10px;
  cursor: pointer;
}
.activeDayBox {
  background: #1ced86;
}
.day {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 105%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #d6edff;
  text-transform: uppercase;
}
.activeDayBox .day {
  color: rgba(24, 51, 46, 0.8);
}
.dayText {
  font-size: 10px;
}
.apyBox {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 105%;
  text-align: center;
  color: #61dea8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(54, 64, 68, 0.7);
  border-radius: 5px;
  padding: 8px 0;
}
.activeDayBox .apyBox {
  color: #61dea8;
}
.apyText {
  font-size: 8px;
  text-transform: uppercase;
}
@media only screen and (max-width: 520px) {
  .durations {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 380px) {
  .apyBox {
    padding: 8px 0;
  }
} ;
