.wrapper {
  width: 100%;
}
.home {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  width: 100%;
  min-height: 100vh;
  padding: 150px 0;

  padding-top: 150px;
  transform: scale(0.8);
  /* for zoom out */
}
@media only screen and (max-width: 991px) {
  .home {
    max-width: 546px;
    margin: 0 auto;
    grid-template-columns: 1fr;
    padding-top: 140px;
    padding-bottom: 80px;
    transform: scale(1);
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ulHolder {
  display: block;
  flex-direction: column;
  gap: 20px;
  border: 1px solid red;
}
