@import url("https://fonts.googleapis.com/css2?family=B612:wght@400;700&family=Inter:wght@300;400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #121619;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --fontFamily: "Inter", sans-serif;
}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  margin: 0;
}
.container {
  max-width: 1116px;
  width: 95%;
  margin: 0 auto;
}
